<cramo-dialog [title]="data.header" [hideClose]="true">
	<div class="conteent">{{ data.text }}</div>
	<div class="footer-buttons">
		<cramo-button
			[text]="appData().global.noText"
			(onclick)="closeModal(false)"
			[fullWidth]="true"
			buttonStyle="stroked"
		/>
		<cramo-button
			buttonColor="primary"
			[text]="appData().global.yesText"
			(onclick)="closeModal(true)"
			[fullWidth]="true"
		/>
	</div>
</cramo-dialog>
